input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.truncated {
  display: block;
  white-space: nowrap; /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
}

.AppBar-label {
  display: block;
  white-space: nowrap; /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.MuiButtonBase-root > .MuiListItemIcon-root {
  min-width: 38px !important;
}

.min-width-38 {
  min-width: 38px;
}

.markdown-text > p {
  padding: 0;
  margin: 0;
}

html {
  overscroll-behavior-y: contain;
}

body {
  overscroll-behavior-y: contain;
}

/* Mobiscroll overrides */
.mbsc-schedule-event-all-day-inner {
  height: auto;
}

.mbsc-schedule-event-range,
.mbsc-material.mbsc-event-time {
  display: none !important;
}

.mbsc-material.mbsc-schedule-event-background {
  opacity: 0.1;
  transition: "opacity .15s ease-in-out";
}

.mbsc-material.mbsc-schedule-event-inner {
  color: #000 !important;
  padding: 0.1em 0.5em;
}
.mbsc-material.mbsc-list-item {
  color: #000 !important;
}

.mbsc-material.mbsc-schedule-event::before {
  content: "";
  border-bottom-left-radius: 0.1875em;
  border-top-left-radius: 0.1875em;
  position: absolute;
  bottom: 1px;
  top: 1px;
  left: 1px;
  right: 1px;
  background: white;
}
.mbsc-material.mbsc-schedule-event::after {
  content: "";
  border-bottom-left-radius: 0.1875em;
  border-top-left-radius: 0.1875em;
  position: absolute;
  bottom: 1px;
  top: 1px;
  left: 1px;
  background: currentColor;
  width: 0.1875em;
}

.mbsc-material.mbsc-list-item.mbsc-hover:before {
  opacity: 0.8;
  background: white !important;
}
.mbsc-material.mbsc-list-item::before {
  content: "";
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 3px;
  right: 0px;
  background: white;
  opacity: 0.9;
  transition: opacity 0.15s ease-in-out;
}

.mbsc-material.mbsc-popover-list .mbsc-list-item {
  padding: 0.5em 0.625em !important;
}
